import {Logger, LogLevel} from "../debug/Logger";
import {Config} from "../environment";
import IConfig from "../environment/IConfig";
import {API, ServiceManager} from "../services";
import BrowserStorageProvider from "../services/browserstorage/BrowserStorageProvider";
import CredentialManager from "../services/credentialmanager/CredentialManager";
import EventBus from "../services/eventbus/EventBus";
import FormManager from '../services/formManager/FormMananger'
import {HeadService} from "../services/HeadService";
import HttpProviderType from "../services/httpclient/HttpProviderType";
import FetchClient from "../services/httpclient/providers/FetchClient";

class Bootstrapper {
    static Initialise(config: IConfig): Promise<boolean> {
        Logger.Log(LogLevel.Debug, 'Bootstrapper', 'Beginning bootstrap process', config);

        return new Promise((resolve) => {
            Logger.SetLevel(config.Logging.LogLevel);

            Config.Initialise(config);

            this.InitServices();

            Logger.Log(LogLevel.Debug, 'Bootstrapper', 'Finished bootstrapping process');

            resolve(true);
        });
    }

    private static InitServices(): void {
        //Event Bus
        // Note: EventBus does not appear to be used anywhere.
        ServiceManager.Register(new EventBus())

        //Browser Storage
        ServiceManager.Register(new BrowserStorageProvider());

        //Credential Manager
        ServiceManager.Register(new CredentialManager());

        //Form Manager
        // Note: FormManager does not appear to be used anywhere.
        ServiceManager.Register(new FormManager());

        //HTTP Client
        switch (Config.Http.Provider) {
            case HttpProviderType.Fetch:
                ServiceManager.Register(new FetchClient());
                break;
        }

        /* eslint-disable */
        ServiceManager.Register(new HeadService({
            meta: {
                site_name: 'Trading Mate',
                title_delimiter: ' | ',
                default: {title: 'Trading Mate'},
                items: []
            }
        }));

        ServiceManager.Register(new API());
    }
}

export default Bootstrapper;
