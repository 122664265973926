
import Card from '@/components/cards/Card.vue'
import Cards from '@/components/cards/Cards.vue'
import { CategorySelector, InputText, ZoneSelector } from '@/components/inputs'
import { SearchBar, SearchBarItem } from '@/components/search'
import { FormPage } from '@/mixins'
// import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
// import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import MemberCard from './FindABusiness/MemberCard.vue'
import Modal from '@/components/actions/Modal.vue'
import BusinessBranchFeedbackFilter from 'tradingmate-modules/src/filters/BusinessBranchFeedbackFilter'
import BusinessBranchFeedbackType
  from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchFeedbackType'
import BusinessBranchFeedbackCreateDto
  from 'tradingmate-modules/src/models/api/businesses/branches/Feedback/BusinessBranchFeedbackCreateDto'
import InputSelect from '@/components/inputs/InputSelect.vue'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import Utils from 'tradingmate-modules/src/Utils'
import PackageCheckoutQueries from '../../../../tradingmate-modules/src/models/common/PackageCheckoutQueries'
import FieldCheckbox from '@/components/forms/fields/FieldCheckbox.vue'
import FormCheckboxField from '@/components/forms/FormCheckboxField.vue'
import InputCheckbox from '@/components/inputs/InputCheckbox.vue'
import WholeSearchBar from '@/components/search/WholeSearchBar.vue'
import { Logger, LogLevel } from 'tradingmate-modules/src/debug/Logger'
import store from '../../store'

@Component({
  components: {
    WholeSearchBar,
    InputCheckbox,
    FormCheckboxField,
    FieldCheckbox,
    InputSelect,
    Cards,
    Card,
    MemberCard,
    SearchBar,
    SearchBarItem,
    InputText,
    ZoneSelector,
    CategorySelector,
    Modal
  }
})
export default class Dashboard extends Mixins(FormPage) {
  private branch: BusinessBranchModel | null = null

  private branches: BusinessBranchModel[] | null = null

  private userName: string = this.$store.state.identity.User.FullName

  private feedbackModelActive = false
  private feedbackCreate: BusinessBranchFeedbackCreateDto ={
    Type: BusinessBranchFeedbackType.SignupSource,
    Feedback: ''
  }

  private feedbackSelection =''
  private feedbackText = ''
  feedbackItems: DropdownListItem[] = [
    { Key: 'Facebook', Label: 'Facebook', Value: 'Facebook' },
    { Key: 'Instagram', Label: 'Instagram', Value: 'Instagram' },
    { Key: 'Linkedin', Label: 'Linkedin', Value: 'Linkedin' },
    { Key: 'TV', Label: 'TV', Value: 'TV' },
    { Key: 'Referring Trading Mate', Label: 'Referring Trading Mate', Value: 'Referring Trading Mate' },
    { Key: 'Other', Label: 'Other', Value: 'Other' }
  ]

  mounted (): void {
    this.getNewBusinesses()
    this.checkFirstLogin()
    const credential = Services.CredentialManager.GetOAuthCredential()
    console.log(credential)
    this.branch = this.$store.state.identity.BusinessBranch
  }

  get expirationDate (): string {
    if (!this.branch) return ''
    const expirationDateRaw = this.branch.DateExpiration
    return Utils.isoToDisplayDate(expirationDateRaw)
  }

  onSubmitFeedback (): void {
    console.log('feedback')
    const id = this.$store.state.identity.BusinessBranch.BusinessBranchId ?? null
    console.log(id)
    if (id === null) return
    this.feedbackCreate.Feedback = this.feedbackSelection + ' - ' + this.feedbackText
    Services.API.Businesses.FeedbackCreate(id, this.feedbackCreate)
    this.feedbackModelActive = false
  }

  getNewBusinesses (): void {
    this.loading = true
    Services.API.Businesses.GetNewBranches(24)
      .then((responce) => {
        this.branches = responce
      }).catch((errors) => {
        console.log(errors)
      }).finally(() => {
        this.loading = false
      })
  }

  async checkFirstLogin (): Promise<void> {
    // Update branch status again, needed here to ensure update after checkout
    try {
      const branches = await Services.API.Users.GetBusinessBranches()
      this.$store.commit('identity/setBranch', branches[0])
    } catch (error) {
      Logger.Log(LogLevel.Critical, error)
    }

    const id = this.$store.state.identity.BusinessBranch.BusinessBranchId ?? null
    if (id === null) return
    const filter = new BusinessBranchFeedbackFilter({
      BusinessBranchId: id, Type: BusinessBranchFeedbackType.SignupSource
    })
    Services.API.Businesses.FeedbackQuery(filter)
      .then((result) => {
        if (result.Items && result.Items.length)
        {
          return
        }
        this.feedbackModelActive = true
      })
  }

  navToMembership (): void {
    if (!this.branch) return
    this.$router.push({ name: 'Membership', params: { id: this.branch.BusinessBranchId } })
  }

  navToWebsite (): void {
    this.navToMembership()
  }

  onWebsiteEditClick (): void {
    if (!this.branch) return
    this.$router.push({ name: 'My Website Edit', params: { id: this.branch.BusinessBranchId } })
  }

  onProfilePicClicked (): void {
    if (!this.branch) return
    this.$router.push({
      name: 'Membership',
      params: { id: this.branch.BusinessBranchId },
      query: { photo: 'true' }
    })
  }

  onPromoPackClicked (): void {
    if (!this.branch) return
    this.$router.push({
      name: 'Membership',
      params: { id: this.branch.BusinessBranchId },
      query: { p: PackageCheckoutQueries.Promo }
    })
  }

  onNewOfferClick (): void {
    this.$router.push({ name: 'Create Offers' })
  }

  onNewEventClick (): void {
    this.$router.push({ name: 'Create Event' })
  }

  onNewBlogClick (): void {
    this.$router.push({ name: 'Create Blog' })
  }
}
