
import FormCheckboxField from '@/components/forms/FormCheckboxField.vue'
import { InputCheckbox, InputNumber, InputSelect, InputText } from '@/components/inputs'
import { FormPage } from '@/mixins'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import BusinessBranchNumEmployees from 'tradingmate-modules/src/models/api/businesses/BusinessBranchNumEmployees'
import BusinessBranchStates from 'tradingmate-modules/src/models/api/businesses/BusinessBranchStates'
import BusinessBranchDisplayAddress from 'tradingmate-modules/src/models/api/businesses/BusinessBranchDisplayAddress'
import BusinessBranchDetailUpdateModel from 'tradingmate-modules/src/models/api/businesses/BusinessBranchDetailUpdateModel'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import { BusinessBranchModel, SocialLinkType } from 'tradingmate-modules/src/models/api/businesses'

@Component({
  components: {
    InputText,
    InputSelect,
    FormCheckboxField,
    InputCheckbox,
    InputNumber
  }
})

export default class SignupBusiness extends Mixins(FormPage) {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch

  private NoAbn = false

  private showExpressOffer = true;

  private businessDetailModel: BusinessBranchDetailUpdateModel = {
    ABN: '',
    NoABNReason: '',
    TradingAddress: '',
    Suburb: '',
    PostCode: '',
    StateId: BusinessBranchStates.Queensland,
    DisplayAddress: BusinessBranchDisplayAddress.Display,
    PostalAddress: '',
    NumEmployees: BusinessBranchNumEmployees.oneToFourteen,
    NonProfitCharity: false
  }

  private displayAddressSelections: DropdownListItem[] = [
    { Key: 'Display', Label: 'Display', Value: BusinessBranchDisplayAddress.Display },
    { Key: 'Do not display', Label: 'Do not display', Value: BusinessBranchDisplayAddress.DoNotDisplay },
    { Key: 'Suburb only', Label: 'Suburb only', Value: BusinessBranchDisplayAddress.SuburbOnly }
  ]

  private states: DropdownListItem[] = [
    { Key: BusinessBranchStates.Queensland, Label: 'Queensland', Value: BusinessBranchStates.Queensland },
    { Key: BusinessBranchStates.NewSouthWales, Label: 'New South Wales', Value: BusinessBranchStates.NewSouthWales },
    { Key: BusinessBranchStates.Victoria, Label: 'Victoria', Value: BusinessBranchStates.Victoria },
    { Key: BusinessBranchStates.Tasmania, Label: 'Tasmania', Value: BusinessBranchStates.Tasmania },
    { Key: BusinessBranchStates.SouthAustralia, Label: 'South Australia', Value: BusinessBranchStates.SouthAustralia },
    { Key: BusinessBranchStates.WesternAustralia, Label: 'Western Australia', Value: BusinessBranchStates.WesternAustralia },
    { Key: BusinessBranchStates.NorthernTerritory, Label: 'Northern Territory', Value: BusinessBranchStates.NorthernTerritory },
    { Key: BusinessBranchStates.AustralianCapitalTerritory, Label: 'ACT', Value: BusinessBranchStates.AustralianCapitalTerritory }
  ]

  private numEmployees: DropdownListItem[] = [
    { Key: BusinessBranchNumEmployees[0], Label: '1-14', Value: BusinessBranchNumEmployees.oneToFourteen },
    { Key: BusinessBranchNumEmployees[1], Label: '15+', Value: BusinessBranchNumEmployees.fifteenPlus }
  ]

  mounted (): void {
    if (this.branch.ABN)
    {
      this.businessDetailModel.ABN = this.branch.ABN
    }
    if (this.branch.Postcode)
    {
      this.businessDetailModel.PostCode = this.branch.Postcode
    }
  }

  handleBusinessCreate (): void {
    this.loading = true
    this.httpError = null

    const branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch

    console.log('handle Business Create')
    console.log(branch.BusinessBranchId)

    Services.API.Businesses.UpdateBusinessBranchDetails(branch.BusinessBranchId, this.businessDetailModel)
      .then((result) => {
        this.$store.commit('identity/setBranch', result)
        this.$emit('next')
      }).catch((errors) => {
        console.log(errors)
        this.httpError = errors
      }).finally(() => {
        this.loading = false
      })
  }

  onExpressSignUp (): void {
    this.$emit('expressSignup')
  }

  get hasFacebookOrWebsite (): boolean {
    if (!this.branch) return false
    const facebook = this.branch.SocialLinks?.some(link => link.LinkType === SocialLinkType.Facebook)
    const website = this.branch.SocialLinks?.some(link => link.LinkType === SocialLinkType.Website)
    if (facebook || website) return true
    return false
  }
}

